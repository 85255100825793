define("whats-app/routes/app", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    setupController: function (controller, model) {
      controller.set('model', model);

      if (this.session.isAuthenticated) {
        if (!this.session.data.authenticated.isAdmin) this.transitionTo('whatsapp');
        controller.set('conta', localStorage.getItem('__whats_user'));
        controller.set('haveBot', localStorage.getItem('__whats_botEnable'));
        controller.set('phone', localStorage.getItem('__whats_phone'));
        controller.set('token_admin', localStorage.getItem('__whats_id'));
        controller.set('generateToken', localStorage.getItem('__whats_id'));
        controller.set('email', localStorage.getItem('__whats_email'));
        controller.send('permited');
        controller.send('loadUsers');
        controller.send('loadClients');
        controller.send('loadCategorys');
        Ember.run.later(this, () => {
          try {} catch (error) {
            console.log(error);
          }
        }, 500);
      }
    }
  });

  _exports.default = _default;
});