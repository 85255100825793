define("whats-app/helpers/format-timestamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTimestamp = formatTimestamp;
  _exports.default = void 0;

  function formatTimestamp([timestamp]) {
    return new Date(timestamp * 1000).toLocaleString();
  }

  var _default = Ember.Helper.helper(formatTimestamp);

  _exports.default = _default;
});