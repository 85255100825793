define("whats-app/controllers/reset-password", ["exports", "whats-app/http/methods", "sweetalert2"], function (_exports, _methods, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.url = window.location.href;
    },

    url: "",
    password: "",
    password2: "",
    actions: {
      resetPassword: function () {
        if (this.password.length < 8) {
          _sweetalert.default.fire({
            title: "A senha deve ter pelo menos 8 caracteres",
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (this.password.trim() !== this.password2.trim()) {
          _sweetalert.default.fire({
            title: "As senhas não conferem",
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else {
          _methods.default.post('reset-password', {
            password: this.password,
            password2: this.password2,
            params: this.url
          }).then(res => {
            Ember.set(this, 'password', '');
            Ember.set(this, 'password2', '');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
            this.transitionToRoute('login-admin');
          }).catch(err => {
            _sweetalert.default.fire({
              title: err.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });
        }
      }
    }
  });

  _exports.default = _default;
});