define("whats-app/controllers/signup", ["exports", "whats-app/http/methods", "sweetalert2"], function (_exports, _methods, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Signup = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class Signup extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "name", "");

      _defineProperty(this, "email", "");

      _defineProperty(this, "email2", "");

      _initializerDefineProperty(this, "phone", _descriptor, this);

      _defineProperty(this, "password", "");

      _defineProperty(this, "password2", "");
    }

    updatePhoneNumber(phonea) {
      this.phone = phonea;
    }

    signup() {
      if (/\S+@\S+\.\S+/.test(this.email) === false) {
        _sweetalert.default.fire({
          title: 'E-mail inválido',
          icon: "warning"
        });

        document.body.classList.remove('swal2-height-auto');
        document.body.classList.remove('swal2-shown');
      } else if (this.email.trim() !== this.email2.trim()) {
        _sweetalert.default.fire({
          title: 'Os emails não conferem',
          icon: "warning"
        });

        document.body.classList.remove('swal2-height-auto');
        document.body.classList.remove('swal2-shown');
      } else if (this.phone.trim().length < 10) {
        _sweetalert.default.fire({
          title: 'Numero do WhatsApp invalido. Digite o numero com DDD e DDI',
          icon: "warning"
        });

        document.body.classList.remove('swal2-height-auto');
        document.body.classList.remove('swal2-shown');
      } else if (this.password.length < 8) {
        _sweetalert.default.fire({
          title: 'A senha deve ter pelo menos 8 caracteres',
          icon: "warning"
        });

        document.body.classList.remove('swal2-height-auto');
        document.body.classList.remove('swal2-shown');
      } else if (this.password.trim() !== this.password2.trim()) {
        _sweetalert.default.fire({
          title: 'As senhas não conferem',
          icon: "warning"
        });

        document.body.classList.remove('swal2-height-auto');
        document.body.classList.remove('swal2-shown');
      } else {
        _methods.default.post('signup', {
          name: this.name.trim(),
          email: this.email.trim(),
          email2: this.email2.trim(),
          phone: this.phone.trim(),
          password: this.password.trim(),
          password2: this.password2.trim()
        }).then(res => {
          Ember.set(this, 'name', '');
          Ember.set(this, 'email', '');
          Ember.set(this, 'email2', '');
          Ember.set(this, 'phone', '');
          Ember.set(this, 'password', '');
          Ember.set(this, 'password2', '');

          _sweetalert.default.fire({
            title: 'Sua conta foi criada com sucesso!',
            icon: "success"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
          this.transitionToRoute('login-admin');
        }).catch(err => {
          _sweetalert.default.fire({
            title: err.responseJSON.message,
            icon: "error"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updatePhoneNumber", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updatePhoneNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "signup", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "signup"), _class.prototype)), _class));
  _exports.default = Signup;
});