define("whats-app/authenticators/custom", ["exports", "jquery", "ember-simple-auth/authenticators/base", "whats-app/config/environment", "sweetalert2"], function (_exports, _jquery, _base, _environment, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    restore(data) {
      try {
        return Ember.run(() => {
          return _jquery.default.ajax({
            type: 'POST',
            url: `${_environment.default.APP.api}/login`,
            contentType: 'application/json',
            dataType: 'json',
            headers: {
              'Authorization': `Bearer ${data.access_token}`,
              'X-account-id': localStorage.getItem('__whats_id')
            },
            statusCode: {
              401: function (error) {
                window.alert(error.responseJSON.message || 'Incorrect username/password. Try again...');
              },
              403: function (error) {
                window.alert(error.responseJSON.message || 'Account expired/inactive');
              },
              404: function (error) {
                window.alert(error.responseJSON.message || 'Error Find/Save account');
              }
            }
          }).then(res => {
            localStorage.setItem('__whats_id', res.id);
            localStorage.setItem('__whats_user', res.name);
            localStorage.setItem('__whats_botEnable', res.botEnable);
            localStorage.setItem('__whats_phone', res.phone);
            localStorage.setItem('__whats_username', res.username);
            localStorage.setItem('__whats_email', res.email);
            localStorage.setItem('__whats_admin', res.isAdmin);
            localStorage.setItem('__whats_access_token', res.access_token);
            localStorage.setItem('__whats_session', res.session);
            localStorage.setItem('__whats_user_accessAllCategorys', res.accessAllCategorys);
            localStorage.setItem('__whats_user_categorys_ids', res.categorys_id);
            localStorage.setItem('__whats_user_categorys_names', res.categorys_name);
            return res;
          }, error => {
            _sweetalert.default.fire({
              title: 'Falha autenticação: ' + error.responseJSON.message,
              icon: "warning"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
            return Ember.RSVP.reject();
          }).catch(error => {
            _sweetalert.default.fire({
              title: 'Falha autenticação: ' + error.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });
        });
      } catch (error) {
        console.log(error);
      }
    },

    authenticate(options) {
      try {
        const credentials = {
          email: options.email,
          password: options.password,
          token_admin: options.token_admin
        };
        return Ember.run(() => {
          return _jquery.default.ajax({
            type: 'POST',
            url: `${_environment.default.APP.api}/login`,
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(credentials),
            headers: {},
            statusCode: {
              401: function (error) {
                window.alert(error.responseJSON.message || 'Incorrect email/password. Try again...');
              },
              403: function (error) {
                window.alert(error.responseJSON.message || 'Account expired/inactive');
              },
              404: function (error) {
                window.alert(error.responseJSON.message || 'Error Find/Save account');
              }
            }
          }).then(res => {
            localStorage.setItem('__whats_id', res.id);
            localStorage.setItem('__whats_user', res.name);
            localStorage.setItem('__whats_botEnable', res.botEnable);
            localStorage.setItem('__whats_phone', res.phone);
            localStorage.setItem('__whats_username', res.username);
            localStorage.setItem('__whats_email', res.email);
            localStorage.setItem('__whats_admin', res.isAdmin);
            localStorage.setItem('__whats_access_token', res.access_token);
            localStorage.setItem('__whats_session', res.session);
            localStorage.setItem('__whats_user_accessAllCategorys', res.accessAllCategorys);
            localStorage.setItem('__whats_user_categorys_ids', res.categorys_id);
            localStorage.setItem('__whats_user_categorys_names', res.categorys_name);
            return res;
          }, error => {
            _sweetalert.default.fire({
              title: 'Falha autenticação: ' + error.responseJSON.message,
              icon: "warning"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
            return Ember.RSVP.reject();
          }).catch(error => {
            _sweetalert.default.fire({
              title: 'Falha autenticação: ' + error.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });
        });
      } catch (error) {
        console.log(error);
      }
    },

    invalidate() {
      return Ember.RSVP.resolve();
    }

  });

  _exports.default = _default;
});