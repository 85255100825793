define("whats-app/http/methods", ["exports", "jquery", "whats-app/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function post(url, data) {
    return _jquery.default.ajax({
      url: `${_environment.default.APP.api}/${url}`,
      type: 'POST',
      dataType: 'json',
      data: JSON.stringify(data),
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('__whats_access_token')}`,
        'X-account-id': localStorage.getItem('__whats_id'),
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      }
    }).then(res => {
      return res;
    }).catch(error => {
      throw error;
    });
  }

  function get(url, id) {
    const result = _jquery.default.ajax({
      url: id ? `${_environment.default.APP.api}/${url}/${id}` : `${_environment.default.APP.api}/${url}`,
      type: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('__whats_access_token')}`,
        'X-account-id': localStorage.getItem('__whats_id'),
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      }
    }).then(res => {
      return res;
    }).catch(error => {
      throw error;
    });

    if (result) return result;
  }

  function put(url, data) {
    const result = _jquery.default.ajax({
      url: `${_environment.default.APP.api}/${url}`,
      type: 'PUT',
      dataType: 'json',
      data: JSON.stringify(data),
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('__whats_access_token')}`,
        'X-account-id': localStorage.getItem('__whats_id'),
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      }
    }).then(res => {
      return res;
    }).catch(error => {
      throw error;
    });

    if (result) return result;
  }

  function remove(url, id) {
    return _jquery.default.ajax({
      url: `${_environment.default.APP.api}/${url}/${id}`,
      type: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('__whats_access_token')}`,
        'X-account-id': localStorage.getItem('__whats_id'),
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      }
    }).then(res => {
      return res;
    }).catch(error => {
      throw error;
    });
  }

  var _default = {
    post,
    get,
    remove,
    put
  };
  _exports.default = _default;
});