define("whats-app/routes/whatsapp", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    setupController: function (controller, model) {
      controller.set('model', model);

      if (this.session.isAuthenticated) {
        if (this.session.data.authenticated.isAdmin) controller.set('isAdmin', true);else controller.set('isAdmin', false);
        controller.set('userName', localStorage.getItem('__whats_username'));
        controller.set('accessAllCategorys', localStorage.getItem('__whats_user_accessAllCategorys'));
        controller.set('attendant_categorys_names', localStorage.getItem('__whats_user_categorys_names'));
        controller.set('attendant_categorys_ids', localStorage.getItem('__whats_user_categorys_ids'));
        controller.set('countChats', 0);
        controller.send('loadChats');
        controller.send('loadCategorys');
        controller.send('clientInfo');
      }
    }
  });

  _exports.default = _default;
});