define("whats-app/components/custom-textarea-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    didRender() {
      this.$().keypress(function (event) {
        if (event.keyCode == 13 && !event.shiftKey) {
          event.preventDefault();
        }
      });
      this.$().focus();
    }

  });

  _exports.default = _default;
});