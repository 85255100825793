define("whats-app/controllers/clients", ["exports", "whats-app/http/methods"], function (_exports, _methods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.send('loadClients');
    },

    url: 'clients',
    showLoading: false,
    clients: [],
    email: localStorage.getItem('__whats_email'),
    actions: {
      loadClients: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get(this.url, this.email).then(res => {
          Ember.set(this, 'clients', res.clients);
        }).catch(error => {
          console.log('Falha ao carregar os Clients: ' + error.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });
      },
      block: function (id, block) {
        if (window.confirm('Tem certeza que deseja realizar essa operação?')) {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.url, {
            email: this.email,
            block: block,
            client: id
          }).then(res => {
            const clients = this.clients;
            const index = clients.findIndex(c => c._id == id);
            Ember.set(clients[index], 'blocked', !block);
            alert(res.message);
          }).catch(error => {
            console.log('Falha na operação com o Client: ' + error.responseJSON.message);
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      },
      habBot: function (id, habBot) {
        if (window.confirm('Tem certeza que deseja realizar essa operação?')) {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.url + '/bot', {
            email: this.email,
            habBot: habBot,
            client: id
          }).then(res => {
            const clients = this.clients;
            const index = clients.findIndex(c => c._id == id);
            Ember.set(clients[index], 'botEnable', !habBot);
            alert(res.message);
          }).catch(error => {
            console.log('Falha na operação com o Client: ' + error.responseJSON.message);
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});