define("whats-app/routes/application", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "whats-app/config/environment"], function (_exports, _jquery, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    actions: {
      invalidateSession() {
        try {
          _jquery.default.ajax({
            url: `${_environment.default.APP.api}/logout`,
            type: 'GET',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('__whats_access_token')}`,
              'X-account-id': localStorage.getItem('__whats_id'),
              'Content-Type': 'application/vnd.api+json',
              'Accept': 'application/vnd.api+json'
            },
            complete: () => {
              window.localStorage.clear();
              this.session.invalidate();
              this.transitionTo('login-admin');
            }
          });
        } catch (error) {
          console.log(error);
        }
      }

    }
  });

  _exports.default = _default;
});