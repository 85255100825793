define("whats-app/router", ["exports", "whats-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    //account
    this.route('login');
    this.route('login-admin');
    this.route('signup');
    this.route('password');
    this.route('reset-password'); //auth

    this.route('whatsapp');
    this.route('app');
    this.route('users');
    this.route('categorys');
    this.route('clients');
  });
  var _default = Router;
  _exports.default = _default;
});