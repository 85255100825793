define("whats-app/controllers/users", ["exports", "whats-app/http/methods"], function (_exports, _methods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.send('loadUsers');
    },

    url: 'users',
    username: "",
    password: "",
    password2: "",
    email: "",
    email2: "",
    showLoading: false,
    users: [],
    client_name: localStorage.getItem('__whats_user'),
    actions: {
      users() {
        let categorys = [];
        var el = document.getElementById('categorysSelect');
        var tops = el.getElementsByTagName('input');

        for (var i = 0, len = tops.length; i < len; i++) {
          if (tops[i].checked === true) {
            categorys.push(tops[i].id);
          }
        }

        if (!this.username || this.username.length < 5) {
          alert('O nome de usuário deve ter pelo menos 5 caracteres.');
        } else if (/\S+@\S+\.\S+/.test(this.email) === false) {
          alert('E-mail invalido');
        } else if (this.email.trim() !== this.email2.trim()) {
          alert('Os emails não conferem');
        } else if (this.password.length < 8) {
          alert('A senha deve ter pelo menos 8 caracteres.');
        } else if (this.password.trim() !== this.password2.trim()) {
          alert('As senhas não conferem');
        } else if (categorys.length == 0) {
          alert('Selecione pelo menos uma categoria.');
        } else {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.url, {
            username: this.username,
            password: this.password,
            password2: this.password2,
            email: this.email,
            email2: this.email2,
            categorys: categorys
          }).then(res => {
            Ember.set(this, 'users', res.users);
            Ember.set(this, 'username', '');
            Ember.set(this, 'password', '');
            Ember.set(this, 'password2', '');
            Ember.set(this, 'email', '');
            Ember.set(this, 'email2', '');
            alert(res.message);
          }).catch(err => {
            alert('Falha ao adicionar usuários: ' + err.responseJSON.message);
          });

          Ember.set(this, 'showLoading', false);
        }
      },

      loadUsers: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get(this.url).then(res => {
          Ember.set(this, 'users', res.users);
          Ember.set(this, 'categorys', res.categorys);
          this.set('category', res.categorys[0]);
        }).catch(error => {
          console.log('Falha ao carregar usuários: ' + error.responseJSON.message);
        });

        Ember.set(this, 'showLoading', false);
      },
      delete: function (id) {
        if (window.confirm('Tem certeza que deseja apagar esse usuário?')) {
          Ember.set(this, 'showLoading', true);

          _methods.default.remove(this.url, id).then(res => {
            const users = this.get('users');
            let new_users = Ember.copy(users, true);
            Ember.set(this, 'users', new_users.filter(u => u._id !== id));
            alert(res.message);
          }).catch(error => {
            console.log('Falha ao remover usuário: ' + error.responseJSON.message);
          });

          Ember.set(this, 'showLoading', false);
        }
      },
      selectCategory: function (value) {
        this.set('category', value);
      }
    }
  });

  _exports.default = _default;
});