define("whats-app/controllers/password", ["exports", "whats-app/http/methods", "sweetalert2"], function (_exports, _methods, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: "",
    actions: {
      password() {
        if (/\S+@\S+\.\S+/.test(this.email) === false) {
          _sweetalert.default.fire({
            title: "E-mail invalido",
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else {
          _methods.default.post('password', {
            email: this.email.trim()
          }).then(res => {
            Ember.set(this, 'email', '');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
            this.transitionToRoute('login-admin');
          }).catch(err => {
            _sweetalert.default.fire({
              title: err.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });
        }
      }

    }
  });

  _exports.default = _default;
});