define("whats-app/utils/emojilist", ["exports", "ember-emojipalette/utils/emojilist"], function (_exports, _emojilist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emojilist.default;
    }
  });
});