define("whats-app/controllers/login-admin", ["exports", "sweetalert2"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      authenticateAdmin() {
        const {
          email,
          password
        } = this;

        if (!email || !password) {
          _sweetalert.default.fire({
            title: "Entre com o E-mail e a Senha",
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (password.length < 8) {
          _sweetalert.default.fire({
            title: "A senha deve ter pelo menos 8 caracteres",
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else {
          this.session.authenticate('authenticator:custom', {
            token_admin: null,
            email: email.trim(),
            password: password.trim()
          }).then(() => {
            this.set('email', null);
            this.set('password', null);
          });
        }
      }

    }
  });

  _exports.default = _default;
});