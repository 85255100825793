define("whats-app/components/ember-mask-input", ["exports", "ember-mask-input/components/ember-mask-input"], function (_exports, _emberMaskInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberMaskInput.default;
    }
  });
});