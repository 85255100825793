define("whats-app/services/socket", ["exports", "whats-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    socketRef: null,
    qttySentMonth: 0,
    info: null,
    state: 'UNKNOWN',
    qrcode: null,
    isReady: null,
    isConnected: null,
    wWebVersion: null,
    message_received: null,
    chat_unread: null,
    media_uploaded: null,
    message_ack: null,
    message_revoke_everyone: null,

    init() {
      this._super(...arguments);

      if (this.socketRef === null) {
        let accountId = localStorage.getItem('__whats_id');
        let socket = io.connect(_environment.default.APP.api, {
          query: {
            id: accountId
          }
        });
        socket.on('connect', () => {}); //disconnected

        socket.on('disconnected', reason => {}); //      socket.on("now", (id, timer) => {
        //        console.log("socket::timer", id, timer);
        //        this.set('now', timer);
        //      });
        //********************
        //  application events
        //********************

        socket.on('app_qtty_sent_month', qttySentMonth => {
          this.set('qttySentMonth', qttySentMonth);
        }); //*****************
        //  whatsapp events
        //*****************

        socket.on('whatsapp_disconnected', reason => {
          this.set('isConnected', false);
          this.set('isReady', false);
        });
        socket.on("whatsapp_qrcode", qrcode => {
          this.set('qrcode', qrcode);
        }); //authenticated

        socket.on('whatsapp_authenticated', () => {
          this.set('state', 'AUTHENTICATED');
          this.set('qrcode', '');
        }); //auth_failure

        socket.on('whatsapp_auth_failure', msg => {
          this.set('isConnected', false);
          this.set('isReady', false);
        }); //change_state

        socket.on('whatsapp_change_state', state => {
          if (state === "CONNECTED") this.set('isConnected', true);
          if (state === "DISCONNECTED") this.set('isConnected', false);
          this.set('state', state.toUpperCase());
        }); //whatsapp_info

        socket.on('whatsapp_info', info => {
          this.set('info', info);
        }); //ready

        socket.on('whatsapp_ready', info => {
          this.set('state', 'CONNECTED');
          this.set('isConnected', true);
          this.set('isReady', true);
          this.set('info', info);
        }); //version of WhatsApp Web

        socket.on('whatsapp_version', version => {
          this.set('wWebVersion', version);
        }); //mensagem received

        socket.on("message_received", msg => {
          this.set('message_received', msg);
        });
        socket.on("unread_chat", chat => {
          this.set('unread_chat', chat);
        });
        socket.on("category_update", cat => {
          this.set('category_update', cat);
        });
        socket.on("done_chat", chat => {
          this.set('done_chat', chat);
        });
        socket.on("attendant_update", chat => {
          this.set('attendant_update', chat);
        }); //mensagem uploaded

        socket.on("media_uploaded", msg => {
          this.set('media_uploaded', msg);
        }); //mensagem ack

        socket.on("message_ack", msg => {
          this.set('message_ack', msg);
        }); //mensagem revoke everyone

        socket.on("message_revoke_everyone", msg => {
          this.set('message_revoke_everyone', msg);
        });
        this.set('socketRef', socket);
      }
    }

  });

  _exports.default = _default;
});