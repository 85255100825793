define("whats-app/controllers/categorys", ["exports", "whats-app/http/methods"], function (_exports, _methods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.send('loadCategorys');
    },

    url: 'categorys',
    name: "",
    showLoading: false,
    email: localStorage.getItem('__whats_email'),
    categorys: [],
    actions: {
      categorys() {
        if (!this.name || this.name.length < 3) {
          alert('O nome da categoria deve ter pelo menos 3 caracteres.');
        } else {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.url, {
            email: this.email,
            name: this.name
          }).then(res => {
            this.send('loadCategorys');
            Ember.set(this, 'name', '');
            alert(res.message);
          }).catch(err => {
            alert('Falha ao adicionar categoria: ' + err.responseJSON.message);
          });

          Ember.set(this, 'showLoading', false);
        }
      },

      loadCategorys: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get(this.url).then(res => {
          Ember.set(this, 'categorys', res.categorys);
        }).catch(error => {
          console.log('Falha ao carregar categorias: ' + error.responseJSON.message);
        });

        Ember.set(this, 'showLoading', false);
      },

      delete(id) {
        Ember.set(this, 'showLoading', true);

        _methods.default.post('remove-category', {
          email: this.email,
          id: id
        }).then(res => {
          const categorys = this.get('categorys');
          let new_categorys = Ember.copy(categorys, true);
          Ember.set(this, 'categorys', new_categorys.filter(u => u._id !== id));
          alert(res.message);
        }).catch(err => {
          alert('Falha ao remover categoria: ' + err.responseJSON.message);
        });

        Ember.set(this, 'showLoading', false);
      }

    }
  });

  _exports.default = _default;
});