define("whats-app/controllers/app", ["exports", "whats-app/http/methods", "sweetalert2"], function (_exports, _methods, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.send('loadUsers');
      this.send('loadClients');
      this.send('loadCategorys');
      this.send('loadMessagesAutomatics');
    },

    //variaveisManipulacaoEdicoes
    editAttendant: false,
    idEditUser: "",
    editCat: false,
    idEditCategory: "",
    //fim
    editMessageAutomatic: false,
    idEditMessageAutomatic: '',
    typeMessageAutomatic: '',
    messageAutomatic: '',
    clients: [],
    urlClients: 'clients',
    urlCategorys: 'categorys',
    urlCategorysDs: 'categorys/disable',
    nameCategory: "",
    colorCategory: "",
    messageCallbackCategory: "",
    categorys: [],
    urlMessagesAutomatics: 'messages-automatics',
    messagesAutomatics: [],
    urlUsers: 'users',
    username: "",
    password: "",
    password2: "",
    emailUser: "",
    email2User: "",
    colorUser: "",
    users: [],
    urlRecords: 'records',
    rec_choices_cats: [],
    rec_choices_cats_colors: [],
    rec_choices_cats_qty: [],
    rec_atte_lines_attendants: [],
    rec_atte_lines_colors: [],
    rec_atte_lines_qty: [],
    atendimentosMes: [],
    email: localStorage.getItem('__whats_email'),
    socket: Ember.inject.service(),
    session: Ember.inject.service(),
    ///clipboard
    iconClipBoard: 'content_paste',
    titleClipBoard: 'Copiar Token',
    //envio de mensagem
    messageConversation: '',
    image: '',
    message: '',
    quantitySent: 0,
    intervalSent: 3000,
    //importação de numeros avulso
    numberPhones: "",
    //mensagem de saudação
    greetingMessage: null,
    greetingTriggers: null,
    //efeito de 'loading...' na interface
    showLoading: false,
    mostrarJanelaChat: true,
    //paginacao
    countChats: 0,
    countMessagesConversation: 0,
    isConnected: Ember.computed('socket.{isConnected,isReady}', function () {
      return this.socket.isConnected || this.socket.isReady;
    }),
    qrcodeIsVisible: Ember.computed('socket.qrcode', function () {
      Ember.set(this, 'showLoading', false);
      return this.socket.qrcode !== null && this.socket.qrcode.length > 1;
    }),
    isShowAlertMessageLimit75: Ember.computed('socket.qttySentMonth', function () {
      let percent = this.socket.qttySentMonth / this.session.data.authenticated.monthlyLimit;
      return percent >= 0.75;
    }),
    isShowAlertMessageLimit90: Ember.computed('socket.qttySentMonth', function () {
      let percent = this.socket.qttySentMonth / this.session.data.authenticated.monthlyLimit;
      return percent >= 0.9;
    }),
    numberPhonesCount: Ember.computed('numberPhones', function () {
      return this.numberPhones.split(';').reduce((result, currentValue) => {
        let value = currentValue.replace(/\D/g, '');
        if (value.length === 12 || value.length === 10) ++result.valid;else if (value.length > 0) ++result.invalid;
        return result;
      }, {
        valid: 0,
        invalid: 0
      });
    }),
    //filtros
    contactsQuery: '',
    contactsFiltered: Ember.computed('contacts.[]', 'contactsQuery', function () {
      let contacts = this.contacts;
      let contactsQuery = this.contactsQuery;
      let regex = new RegExp(contactsQuery, 'i'); //protection: minimum 2 characters

      if (contactsQuery && contactsQuery.trim().length <= 1) {
        return contacts;
      }

      let result = contacts.filter(contact => {
        return regex.test(`${contact.name} ${contact.verifiedName} ${contact.number} ${contact.isBusiness ? 'Business' : 'Pessoal'}`);
      });
      return result || [];
    }),
    //lista de contatos
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    contacts: [],
    contactsUser: Ember.computed.filter('contactsFiltered.[]', contact => contact.isGroup === false && contact.number !== null),
    contactsGroup: Ember.computed.filter('contactsFiltered.[]', contact => contact.isGroup === true),
    contactsSelected: Ember.computed.filter('contactsFiltered.@each.selected', contact => contact.selected === true),
    contactsSent: Ember.computed.filter('contacts.@each.sent', contact => contact.sent === true),
    totalEstimatedTime: Ember.computed('contacts.@each.selected', 'intervalSent', function () {
      //convert seconds to HH:mm:ss
      let contactsSelectedCount = this.contacts.filter(contact => contact.selected === true).length || 0,
          seconds = contactsSelectedCount * parseInt(this.intervalSent * 1.4 / 1000),
          sec = parseInt(seconds % 60),
          min = parseInt(seconds / 60);
      return seconds > 3600 ? new Date(seconds * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0] : min + ":" + (sec.toString().length == 1 ? "0" + sec : sec);
    }),
    percentageSent: Ember.computed('quantitySent', function () {
      return Math.round(this.quantitySent / this.contactsSelected.length * 100) || 0;
    }),
    contactsObserver: Ember.observer('message', 'image', 'intervalSent', 'contacts.@each.{selected,sent,sentTemp}', function () {
      Ember.run.debounce(this, this.contactsSaveStorage, 500);
    }),
    contactsSaveStorage: function () {
      localStorage.setItem("__whatsappMessenger", JSON.stringify({
        token: localStorage.getItem('__whats_id'),
        image: this.image,
        message: this.message,
        contacts: this.contacts,
        intervalSent: this.intervalSent
      }));
    },
    actions: {
      clipBoard: function () {
        Ember.set(this, "titleClipBoard", 'Token Copiado!');
        Ember.set(this, "iconClipBoard", 'assignment_turned_in');
      },
      showWebVersion: function () {
        alert('Rodando WhatsApp Web: V' + (this.socket.wWebVersion || 'UNKNOWN'));
      },
      selectAll: function (value) {
        this.contactsUser.forEach(contact => Ember.set(contact, 'selected', value));
      },
      selectAllGroup: function (value) {
        this.contactsGroup.forEach(contact => Ember.set(contact, 'selected', value));
      },
      selectAllSelected: function (value) {
        this.contactsSelected.forEach(contact => Ember.set(contact, 'selected', value));
      },
      importContacts: function () {
        let valids = [];
        this.numberPhones.split(';').forEach(number => {
          number = number.replace(/\D/g, '');
          if (number.length === 10) number = "55" + number;

          if (number.length === 12) {
            valids.push({
              id: {
                server: "c.us",
                user: number,
                _serialized: `${number}@c.us`
              },
              number: number,
              isBusiness: false,
              isEnterprise: false,
              name: 'Contato Importado',
              shortName: number,
              verifiedName: number,
              statusMute: false,
              type: "in",
              isMe: false,
              isUser: true,
              isGroup: false,
              isWAContact: true,
              isMyContact: true,
              sent: false,
              selected: true
            });
          } else {
            alert(`number invalid: ${number}`);
          }
        });
        this.contacts.pushObjects(valids);
        Ember.set(this, "numberPhones", "");
        alert(`${valids.length} contatos importados com sucesso`);
      },
      readContactsCSV: function (event) {
        const reader = new FileReader();

        reader.onload = () => {
          Ember.set(this, 'numberPhones', reader.result.trim().replace(/(?:\r\n|\r|\n)/g, ';'));
          Ember.set(this, 'pathContactsCSV', null);
        };

        reader.onerror = () => {
          alert('Erro ao ler aquivo CSV');
        };

        if (event.target.files[0].type.includes("csv")) {
          reader.readAsText(event.target.files[0]);
        } else {
          alert("Formato de arquivo invalido");
          Ember.set(this, 'pathContactsCSV', null);
        }
      },
      checkStatus: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get('status').then(res => {
          Ember.set(this, 'isConnected', res.status === 'CONNECTED');
          Ember.set(this.socket, 'state', res.status);

          if (res.status === 'CONNECTED') {
            _sweetalert.default.fire({
              title: "O Whatsapp está conectado!",
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          } else {
            _sweetalert.default.fire({
              title: res.status,
              icon: "warning"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }
        }).catch(error => {
          console.log(error.responseJSON.message);
          Ember.set(this, 'isConnected', false);

          _sweetalert.default.fire({
            title: 'O Whatsapp está desconectado!',
            icon: "error"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });
      },
      loadQrCode: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get('qrcode').then(res => {}).catch(error => {
          _sweetalert.default.fire({
            title: error.responseJSON.message,
            icon: "error"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        }).then(() => {
          Ember.run.later(this, () => _sweetalert.default.fire({
            title: 'Aguarde até que o QR Code seja gerado...',
            icon: "info"
          }), document.body.classList.remove('swal2-height-auto'), document.body.classList.remove('swal2-shown'), 500);
        });
      },
      destroy: function () {
        _methods.default.get('destroy').then(res => {
          Ember.set(this, 'isConnected', false);

          _sweetalert.default.fire({
            title: 'A sessão foi finalizada com sucesso',
            icon: "success"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        }).catch(error => {
          _sweetalert.default.fire({
            title: error.responseJSON.message,
            icon: "error"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        });
      },
      loadContacts: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get('contacts').then(res => {
          res.contacts.forEach(c => c.sent = false);
          res.contacts.forEach(c => c.selected = false);
          Ember.set(this, 'contacts', res.contacts.sort());
        }).catch(error => {
          console.log('Erro ao obter Contatos => ' + error.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });
      },
      permited: function () {
        _methods.default.get('permiteds', localStorage.getItem('__whats_email')).then(res => {
          Ember.set(this, 'permited', res.permited);
        }).catch(err => {
          console.log('Erro ao verificar super usuário => ' + err.responseJSON.message);
        });
      },
      favoriteMessages: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.post('favorite-messages', {
          favorite: this.favorite
        }).then(res => {
          Ember.set(this, 'favoriteMessages', res.texts);
          Ember.set(this, 'favorite', null);
        }).catch(err => {
          console.log('Erro ao adicionar mensagem favorita => ' + err.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });
      },
      deleteFavorite: function (id) {
        if (window.confirm('Tem certeza que deseja apagar essa mensagem?')) {
          Ember.set(this, 'showLoading', true);

          _methods.default.remove('delete-favorite', id).then(res => {
            Ember.set(this, 'favoriteMessages', res.texts);
          }).catch(error => {
            console.log('Falha ao apagar mensagem: ' + error.responseJSON.message);
          });

          Ember.set(this, 'showLoading', false);
        }
      },
      UpdateCategoryConversation: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.post('update-category', {
          chat_id: this.conv,
          attendant: localStorage.getItem('__whats_session'),
          category_id: this.categoryConv
        }).then(res => {
          Ember.set(this, 'conv', '');
          Ember.set(this, 'categoryConv', '');
          alert(res.message);
        }).catch(error => {
          console.log('Falha ao apagar mensagem: ' + error.responseJSON.message);
        });

        Ember.set(this, 'showLoading', false);
      },
      loadClients: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get(this.urlClients, this.email).then(res => {
          Ember.set(this, 'clients', res.clients);
        }).catch(error => {
          console.log('Falha ao carregar os Clients: ' + error.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });
      },
      block: async function (id, block) {
        let {
          value
        } = await _sweetalert.default.fire({
          title: block == true ? 'Tem certeza que deseja desbloquear esse usuário?' : 'Tem certeza que deseja bloquear esse usuário?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#C82333',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#218838'
        });

        if (value) {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.urlClients, {
            email: this.email,
            block: block,
            client: id
          }).then(res => {
            const clients = this.clients;
            const index = clients.findIndex(c => c._id == id);
            Ember.set(clients[index], 'blocked', !block);

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(error => {
            _sweetalert.default.fire({
              title: 'Falha na operação com o Client: ' + error.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      },
      disableCat: async function (id, catDisabled) {
        let {
          value
        } = await _sweetalert.default.fire({
          title: catDisabled == true ? 'Tem certeza que deseja habilitar essa categoria?' : 'Tem certeza que deseja desabilitar essa categoria?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#C82333',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#218838'
        });

        if (value) {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.urlCategorysDs, {
            email: this.email,
            catDisabled: catDisabled,
            catId: id
          }).then(res => {
            const clients = this.categorys;
            const index = clients.findIndex(c => c._id == id);
            Ember.set(clients[index], 'catDisabled', !catDisabled);

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(error => {
            _sweetalert.default.fire({
              title: 'Falha na operação com o Client: ' + error.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      },
      habBot: async function (id, habBot) {
        let {
          value
        } = await _sweetalert.default.fire({
          title: habBot == true ? 'Tem certeza que deseja desabilitar o bot de categorias nível 1 para esse usuário?' : 'Tem certeza que deseja habilitar o bot de categorias nível 1 para esse usuário?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#C82333',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#218838'
        });

        if (value) {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.urlClients + '/bot', {
            email: this.email,
            habBot: habBot,
            client: id
          }).then(res => {
            const clients = this.clients;
            const index = clients.findIndex(c => c._id == id);
            Ember.set(clients[index], 'botEnable', !habBot);

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(error => {
            _sweetalert.default.fire({
              title: 'Falha na operação com o Client: ' + error.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      },
      editLimit: async function (id, addOrRemove, valueX) {
        let {
          value
        } = await _sweetalert.default.fire({
          title: addOrRemove == "add" ? 'Deseja aumentar em 1 o limite de usuários?' : 'Deseja diminuir em 1 o limite de usuários?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#C82333',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#218838'
        });

        if (value) {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.urlClients + '/limitUsers', {
            email: this.email,
            addOrRemove: addOrRemove,
            client: id
          }).then(res => {
            const clients = this.clients;
            const index = clients.findIndex(c => c._id == id);

            if (addOrRemove == "add") {
              Ember.set(clients[index], 'limitUsers', valueX + 1);
            } else {
              Ember.set(clients[index], 'limitUsers', valueX - 1);
            }

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(error => {
            _sweetalert.default.fire({
              title: 'Falha na operação com o Client: ' + error.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      },
      deleteClient: async function (id) {
        let {
          value
        } = await _sweetalert.default.fire({
          title: 'Tem certeza que deseja excluir esse cliente?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#C82333',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#218838'
        });

        if (value) {
          Ember.set(this, 'showLoading', true);

          _methods.default.remove(this.urlClients, id).then(res => {
            this.send('loadClients');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(error => {
            _sweetalert.default.fire({
              title: 'Falha na operação com o Client: ' + error.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      },

      categorys() {
        if (!this.nameCategory || this.nameCategory.length < 3) {
          _sweetalert.default.fire({
            title: 'O nome da categoria deve ter pelo menos 3 caracteres',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.urlCategorys, {
            email: this.email,
            name: this.nameCategory,
            color: this.colorCategory,
            messageCB: this.messageCallbackCategory
          }).then(res => {
            this.send('loadCategorys');
            Ember.set(this, 'nameCategory', '');
            Ember.set(this, 'colorCategory', '');
            Ember.set(this, 'messageCallbackCategory', '');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(err => {
            _sweetalert.default.fire({
              title: 'Falha ao adicionar categoria: ' + err.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });

          Ember.set(this, 'showLoading', false);
        }
      },

      loadCategorys: function () {
        Ember.set(this, 'idEditCategory', '');
        Ember.set(this, 'editCat', false);
        Ember.set(this, 'nameCategory', '');
        Ember.set(this, 'colorCategory', '');
        Ember.set(this, 'messageCallbackCategory', '');
        Ember.set(this, 'showLoading', true);

        _methods.default.get(this.urlCategorys).then(res => {
          Ember.set(this, 'categorys', res.categorys);
        }).catch(error => {
          console.log('Falha ao carregar categorias: ' + error.responseJSON.message);
        });

        Ember.set(this, 'showLoading', false);
      },
      loadMessagesAutomatics: function () {
        Ember.set(this, 'editMessageAutomatic', false);
        Ember.set(this, 'idEditMessageAutomatic', '');
        Ember.set(this, 'typeMessageAutomatic', '');
        Ember.set(this, 'messageAutomatic', '');
        Ember.set(this, 'showLoading', true);

        _methods.default.get(this.urlMessagesAutomatics).then(res => {
          Ember.set(this, 'messagesAutomatics', res.messagesAutomatics);
        }).catch(error => {
          console.log('Falha ao carregar mensagens automáticas: ' + error.responseJSON.message);
        });

        Ember.set(this, 'showLoading', false);
      },

      async deleteCategory(id) {
        let {
          value
        } = await _sweetalert.default.fire({
          title: 'Tem certeza que deseja remover essa categoria?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#C82333',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#218838'
        });

        if (value) {
          Ember.set(this, 'showLoading', true);

          _methods.default.post('remove-category', {
            email: this.email,
            id: id
          }).then(res => {
            this.send('loadCategorys');
            this.send('loadUsers');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(err => {
            _sweetalert.default.fire({
              title: 'Falha ao remover categoria: ' + err.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });

          Ember.set(this, 'showLoading', false);
        }
      },

      async editCategory(id, nameCat, message, color) {
        Ember.set(this, 'editCat', true);
        Ember.set(this, 'idEditCategory', id);
        Ember.set(this, 'nameCategory', nameCat);
        Ember.set(this, 'colorCategory', color);
        Ember.set(this, 'messageCallbackCategory', message);
      },

      async editMessageAutomatic(id, message, typeMessage) {
        Ember.set(this, 'editMessageAutomatic', true);
        Ember.set(this, 'idEditMessageAutomatic', id);
        Ember.set(this, 'typeMessageAutomatic', typeMessage);
        Ember.set(this, 'messageAutomatic', message);
      },

      users() {
        let categorys = [];
        var el = document.getElementById('categorysSelect');
        var tops = el.getElementsByTagName('input');

        for (var i = 0, len = tops.length; i < len; i++) {
          if (tops[i].checked === true) {
            categorys.push(tops[i].id);
          }
        }

        if (!this.username || this.username.length < 5) {
          _sweetalert.default.fire({
            title: 'O nome de usuário deve ter pelo menos 5 caracteres',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (/\S+@\S+\.\S+/.test(this.emailUser) === false) {
          _sweetalert.default.fire({
            title: 'E-mail inválido',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (this.emailUser.trim() !== this.email2User.trim()) {
          _sweetalert.default.fire({
            title: 'Os emails não conferem',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (this.password.length < 8) {
          _sweetalert.default.fire({
            title: 'A senha deve ter pelo menos 8 caracteres',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (this.password.trim() !== this.password2.trim()) {
          _sweetalert.default.fire({
            title: 'As senhas informadas são diferentes',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (categorys.length == 0) {
          _sweetalert.default.fire({
            title: 'Selecione pelo menos uma categoria',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else {
          Ember.set(this, 'showLoading', true);

          _methods.default.post(this.urlUsers, {
            username: this.username,
            password: this.password,
            password2: this.password2,
            email: this.emailUser,
            email2: this.email2User,
            color: this.colorUser,
            categorys: categorys
          }).then(res => {
            Ember.set(this, 'users', res.users);
            Ember.set(this, 'username', '');
            Ember.set(this, 'password', '');
            Ember.set(this, 'password2', '');
            Ember.set(this, 'emailUser', '');
            Ember.set(this, 'email2User', '');
            Ember.set(this, 'colorUser', '');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
            this.send('loadUsers');
          }).catch(err => {
            _sweetalert.default.fire({
              title: 'Falha ao adicionar usuários: ' + err.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });

          Ember.set(this, 'showLoading', false);
        }
      },

      loadUsers: function () {
        Ember.set(this, 'idEditUser', '');
        Ember.set(this, 'editAttendant', false);
        Ember.set(this, 'username', '');
        Ember.set(this, 'password', '');
        Ember.set(this, 'password2', '');
        Ember.set(this, 'emailUser', '');
        Ember.set(this, 'email2User', '');
        Ember.set(this, 'colorUser', '');
        Ember.set(this, 'showLoading', true);
        this.send('loadCategorys');
        this.send('loadMessagesAutomatics');

        _methods.default.get(this.urlUsers).then(res => {
          Ember.set(this, 'users', res.users);
          Ember.set(this, 'categorys', res.categorys);
          this.set('category', res.categorys[0]);
        }).catch(error => {
          console.log('Falha ao carregar usuários: ' + error.responseJSON.message);
        });

        Ember.set(this, 'showLoading', false);
      },
      loadRecords: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get(this.urlRecords).then(res => {
          /* REC_CHOICES */
          this.set('rec_choices_cats', res.rec_choices.cats);
          this.set('rec_choices_cats_colors', res.rec_choices.colors);
          this.set('rec_choices_cats_qty', res.rec_choices.qty);
          /* REC_ATTENDANTS_LINES */
          //this.set('rec_atte_lines_attendants', res.rec_atte_lines.attendants);
          //this.set('rec_atte_lines_colors', res.rec_atte_lines.colors);
          //this.set('rec_atte_lines_qty', res.rec_atte_lines.qty);

          var chartGraph = new Chart(ctx, {
            type: 'line',
            data: {
              labels: res.rec_atte_lines.months,
              datasets: res.rec_atte_lines.qty
            },
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom'
                },
                title: {
                  display: true,
                  text: 'Atendimentos por operador [Gráfico de Linhas]',
                  font: {
                    size: 20
                  }
                }
              }
            }
          });
          var chartGraph3 = new Chart(ctx3, {
            type: 'doughnut',
            data: {
              labels: this.rec_choices_cats,
              datasets: [{
                label: 'Escolhas',
                data: this.rec_choices_cats_qty,
                backgroundColor: this.rec_choices_cats_colors,
                hoverOffset: 4
              }]
            },
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom'
                },
                title: {
                  display: true,
                  text: 'Escolhas por categorias',
                  font: {
                    size: 20
                  }
                }
              }
            }
          });
          var chartGraph5 = new Chart(ctx5, {
            type: 'bar',
            data: {
              labels: res.rec_atte_bars.months,
              datasets: res.rec_atte_bars.qty
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom'
                },
                title: {
                  display: true,
                  text: 'Atendimentos por operador [Gráfico de barras]',
                  font: {
                    size: 20
                  }
                }
              }
            }
          });
          var chartGraph4 = new Chart(ctx4, {
            type: 'bar',
            data: {
              labels: res.rec_atte_bars_TT.months,
              datasets: res.rec_atte_bars_TT.qty
            },
            options: {
              indexAxis: 'y',
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom'
                },
                title: {
                  display: true,
                  text: 'Total de atendimentos consolidado',
                  font: {
                    size: 20
                  }
                }
              }
            }
          });
        }).catch(error => {});

        Ember.set(this, 'showLoading', false);
      },
      deleteUser: async function (id) {
        let {
          value
        } = await _sweetalert.default.fire({
          title: 'Tem certeza que deseja remover esse atendente?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#C82333',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#218838'
        });

        if (value) {
          Ember.set(this, 'showLoading', true);

          _methods.default.remove(this.urlUsers, id).then(res => {
            this.send('loadUsers');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(error => {
            _sweetalert.default.fire({
              title: 'Falha ao remover usuário: ' + error.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });

          Ember.set(this, 'showLoading', false);
        }
      },
      editUser: async function (id) {
        Ember.set(this, 'editAttendant', true);
        Ember.set(this, 'idEditUser', id);
        var cats = '';
        this.send('loadCategorys');
        var rest;
        await _methods.default.get(this.urlUsers, id).then(res => {
          rest = res;
        }).catch(error => {
          _sweetalert.default.fire({
            title: 'Falha ao obter usuário: ' + error.responseJSON.message,
            icon: "error"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        });

        if (rest) {
          Ember.set(this, 'username', rest.user.name);
          Ember.set(this, 'emailUser', rest.user.email);
          Ember.set(this, 'email2User', rest.user.email);
          Ember.set(this, 'colorUser', rest.user.color);
          Ember.set(this, 'password', '');
          Ember.set(this, 'password2', '');
          rest.user.categorys.forEach(async x => {
            cats = await document.getElementById(x._id).click();
          }); //this.username = res.user.name;
          //this.email = res.user.email;
          //this.email2 = res.user.email2;
          //console.log(res.user);
        }
      },
      updateUser: async function (id) {
        let categorys = [];
        var el = document.getElementById('categorysSelect');
        var tops = el.getElementsByTagName('input');

        for (var i = 0, len = tops.length; i < len; i++) {
          if (tops[i].checked === true) {
            categorys.push(tops[i].id);
          }
        }

        if (!this.username || this.username.length < 5) {
          _sweetalert.default.fire({
            title: 'O nome de usuário deve ter pelo menos 5 caracteres',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (/\S+@\S+\.\S+/.test(this.emailUser) === false) {
          _sweetalert.default.fire({
            title: 'E-mail inválido',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (this.emailUser.trim() !== this.email2User.trim()) {
          _sweetalert.default.fire({
            title: 'Os emails não conferem',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (this.password.length < 8 && this.password.length != 0) {
          _sweetalert.default.fire({
            title: 'A senha deve ter pelo menos 8 caracteres',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (this.password.trim() !== this.password2.trim()) {
          _sweetalert.default.fire({
            title: 'As senhas informadas são diferentes',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else if (categorys.length == 0) {
          _sweetalert.default.fire({
            title: 'Selecione pelo menos uma categoria',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else {
          Ember.set(this, 'showLoading', true);

          _methods.default.put(this.urlUsers, {
            idUsuario: id,
            username: this.username,
            password: this.password,
            password2: this.password2,
            email: this.emailUser,
            email2: this.email2User,
            color: this.colorUser,
            categorys: categorys
          }).then(res => {
            Ember.set(this, 'users', res.users);
            Ember.set(this, 'username', '');
            Ember.set(this, 'password', '');
            Ember.set(this, 'password2', '');
            Ember.set(this, 'emailUser', '');
            Ember.set(this, 'email2User', '');
            Ember.set(this, 'colorUser', '');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
            this.send('loadUsers');
          }).catch(err => {
            _sweetalert.default.fire({
              title: 'Falha ao adicionar usuários: ' + err.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });

          Ember.set(this, 'showLoading', false);
        }
      },
      updateCategory: async function (id) {
        if (!this.nameCategory || this.nameCategory.length < 3) {
          _sweetalert.default.fire({
            title: 'O nome da categoria deve ter pelo menos 3 caracteres',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else {
          Ember.set(this, 'showLoading', true);

          _methods.default.put(this.urlCategorys, {
            catId: id,
            email: this.email,
            name: this.nameCategory,
            color: this.colorCategory,
            messageCB: this.messageCallbackCategory
          }).then(res => {
            this.send('loadCategorys');
            Ember.set(this, 'nameCategory', '');
            Ember.set(this, 'colorCategory', '');
            Ember.set(this, 'messageCallbackCategory', '');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(err => {
            _sweetalert.default.fire({
              title: 'Falha ao adicionar categoria: ' + err.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });

          Ember.set(this, 'showLoading', false);
        }
      },
      updateMessageAutomatic: async function (id) {
        if (!this.messageAutomatic || this.messageAutomatic.length <= 0) {
          _sweetalert.default.fire({
            title: 'A mensagem automática não pode ficar vazia',
            icon: "warning"
          });

          document.body.classList.remove('swal2-height-auto');
          document.body.classList.remove('swal2-shown');
        } else {
          Ember.set(this, 'showLoading', true);

          _methods.default.put(this.urlMessagesAutomatics, {
            messageId: id,
            email: this.email,
            message: this.messageAutomatic
          }).then(res => {
            this.send('loadMessagesAutomatics');
            Ember.set(this, 'messageAutomatic', '');

            _sweetalert.default.fire({
              title: res.message,
              icon: "success"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          }).catch(err => {
            _sweetalert.default.fire({
              title: 'Falha ao atualizar mensagem: ' + err.responseJSON.message,
              icon: "error"
            });

            document.body.classList.remove('swal2-height-auto');
            document.body.classList.remove('swal2-shown');
          });

          Ember.set(this, 'showLoading', false);
        }
      },
      selectCategory: function (value) {
        this.set('category', value);
      }
    }
  });

  _exports.default = _default;
});